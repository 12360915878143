import _defineProperty from "D:/vue_code/02_vue/vue3_study/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Options, Vue } from 'vue-class-component';
// import HelloWorld from './components/HelloWorld.vue';
// import MathIndex from './components/MathIndex.vue';
// import MathResult from './components/MathResult.vue';
let App = class App extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "msg", "1000");
    _defineProperty(this, "flag", void 0);
    _defineProperty(this, "initVar", void 0);
  }
  beforeMount() {
    this.initVar = "hello world initVar";
  }
  sayHello() {
    console.log($("#app"));
  }
};
App = __decorate([Options({
  props: {
    msg: String
  },
  data() {
    return {
      flag: "这是一个欢迎组件"
    };
  },
  components: {
    // 注册一个子级组件
    // HelloWorld,
    // MathIndex,
    // HelloGamma
  }
})], App);
export default App;