// 程序的入口文件，ts文件，是main.ts

// 引入createApp函数，创建对应的应用，产生应用的实例对象
import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App.vue'
import {Button,Cell, CellGroup, NumberKeyboard,BackTop,Collapse, CollapseItem} from 'vant'
// 创建app应用返回对应的实例对象，调用mount方法进行挂载

const app = createApp(App)

// app.config.globalProperties.$axios = axios


axios.defaults.baseURL = 'https://dbe4a0c7-e258-441e-b6a6-c488fc90d665-5000-public.devstudio.aliyuncs.com'


app.use(Button).use(Cell).use(CellGroup).use(NumberKeyboard).use(BackTop).use(Collapse).use(CollapseItem)

app.use(VueAxios, axios);
// 也可以从其他文件导入
import { createRouter, createWebHistory } from 'vue-router'
// import MathIndex from './view/MathIndex.vue';

// 创建和挂载
const routes = [
  
  { path: '/', component:()=> import('./view/MathIndex.vue'),meta:{title: '求导、偏导'} },
  { path: '/about', component: ()=>  import("./components/MathResult.vue")  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})
// router.beforeEach((to, from, next) => {
//   if (to.meta.title) { //拦截后设置标题
//     document.title = to.meta.title
//   }
//   next()
// })
app.use(router)

// console.log(App.prototype)
// app.config.globalProperties.test1 = document.getElementById("app1")
// vue.prototype.test = "hello world"
console.log(app)
app.mount('#app')